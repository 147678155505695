<template>
    <div class="cs_formgrid" :class="{ readonly: !this.editing }">
    <template v-for="field in componentData.fields.filter(a => !a?.state || (a?.state === 'Online'))" :key="field.id">
        <div v-if="isFieldVisible(field)" :class="getClassesByField(field)">
        <component
            :is="resolveType(field.type)"
            v-bind="field"
            :value="value(field)"
            :module_object="this.module_object"
            :editing="this.editing"
        >
            <div class="element TextField">
                <label>{{ field.label }}</label>
                <small>{{ field.type }} isn't available yet</small>
            </div>
        </component>
        </div>
    </template>
    </div>
</template>

<script>
import { reactive, defineComponent, watch, computed } from "vue";

import { env } from "config";
import { company } from "store/portal";

// Our components
import Elements from 'components/elements/_index';

export default defineComponent({
    name: "fields",
    props: {
        fields: {
            type: Array,
            required: true
        },
        values: {
            type: Object,
            required: true
        },
        module_object: {
            type: String,
            required: false,
            default: ""
        },
        // This boolean allows us too set all fields to readonly at start and only allow editing when requested
        editing: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        ...Elements
    },
    setup(props) {
        const componentData = reactive({
            fields: []
        });

        const formatFieldData = (fields = []) => {
            componentData.fields = [];
            fields.forEach(field => {
                const bools = [
                    "auto_fill",
                    "multiple_languages",
                    "readonly",
                    "validate",
                    "float"
                ];
                // Reformat our booleans, making sure we get true or false
                bools.forEach(key => {
                    field[key] = (typeof field[key] === "boolean") ? field[key] : (field[key] === "Y");
                });

                // Make sure validation is passed
                if (!field?.required && ("validate" in field)) {
                    field.required = field.validate;
                }

                // Check the field name, if it matched the current env("company_property_name")
                // We make this a hidden field instead with correct value preset
                if (field.name === env("company_property_name")) {
                    field.type = "HiddenField";
                    field.default_value = company.value?.data?.id;
                }

                componentData.fields.push(field);
            });
        };

        const resolveType = (type = "") => {
            return type.split("\\").pop();
        };

        watch(
            () => props.fields.length,
            () => {
                formatFieldData(props.fields)
            },
            {
                immediate: true
            }
        );

        const getClassesByField = (field) => {
            let colClass = field.extra?.colclass || "span12";
            let rowClass = field.extra?.rowclass || "row1";

            if (resolveType(field.type) === "EditorField") {
                colClass = "span12";
            }
            if (resolveType(field.type) === "HiddenField") {
                colClass = "span12 hidden";
            }

            return [ colClass, rowClass ];
        };
        const value = (field) => {
            return computed(() => {
                return props.values?.value?.[`${field.id}`]?.value ?? field.default_value;
            }).value;
        };

        const isFieldVisible = (field) => {
            return computed(() => {
                // When editing, always show
                if (props.editing) {
                    return true;
                }
                // Check for value, if value, show
                const fieldValue = props.values.value[`${field.id}`]?.value ?? field.default_value;
                if (fieldValue) {
                    return true;
                }
                // Default is false
                return false;
            }).value;
        };

        return {
            componentData,
            resolveType,
            getClassesByField,
            value,
            isFieldVisible
        };
    },
});
</script>

<style></style>