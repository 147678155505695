<template>
    <article v-if="!this.hide_empty || componentData.data.length" class="Block Workflowphase Item" :class="{default: (currentLayout == 'kanban'), list: (currentLayout == 'list')}">
        <div class="Heading">
            <h3 class="h3">{{ this.data.title }}</h3>
        </div>
        <div class="inner">
            <workflowcard-item
                v-for="(item, index) in sort(componentData.data)"
                :key="item.id"
                :data="item"
                :layout="currentLayout"
                :workflowboard_id="this.workflowboard_id"
                :workflowphase_id="this.data.id"
                :first="index === 0"
            />
            <template v-if="!componentData.loading && !componentData.data.length && currentLayout === 'list'">
                {{ trans("Momenteel zijn er geen items gekoppeld aan deze fase.") }}
            </template>
        </div>
    </article>
</template>

<script>
import {defineComponent,  reactive, watch, inject, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

// External components
import workflowcardItem from "view/dashboard/includes/workflowcard-item";
import {getDataWithoutLimit} from "helpers/_api";
import trans from "helpers/_translation";

import { generalFilters, resolveFilters, resolveAppFilters } from "store/filters";
import { sort } from "store/sorting";


// Config for API urls used in this vue template
import { config, apiHostname } from "config";

export default defineComponent({
	name: "workflowphase-overview",
	components: {
		workflowcardItem
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		workflowboard_id: {
			type: String,
			required: true
		},


		hide_empty: {
			type: Boolean,
			required: false,
			default: false
		},

		// Extra props for usage within card view, related overview
		related_filter_json: {
			type: String,
			required: false,
			default: "{}"
		}
	},
	setup(props) {

		// Our route, used to get the current card id for relatedcards endpoint
		const route = useRoute();
		// Our configuration and constants
        const viewData = inject("viewData");
		const currentLayout = inject("currentLayout");

        const componentData = reactive({
            data: [],
            loading: true,
            init: false
        });
        const workflowphasesData = inject("workflowphasesData");

        const getData = () => {
            if (componentData.init) return;
            componentData.init = true;

            resolveAppFilters(viewData.extra_filters).then(extra_filters => {
                const filters = resolveFilters([
                    [
                        "FieldFilter",
                        {
                            "column": "state",
                            "value": "Online"
                        }
                    ],
                    ...generalFilters.workflowboard[props.workflowboard_id ? parseInt(props.workflowboard_id) : viewData.workflowboard_id],
                    ...generalFilters.workflowusergroup,
                    ...extra_filters
                ]);

                const phaseFilters = resolveFilters(props.data?.extra?.workflowfilter ?? []);
                if (phaseFilters) {
                    filters.push(...phaseFilters);
                }

                // console.log("generalFilters.workflowboard", JSON.parse(JSON.stringify(generalFilters.workflowboard)));
                // console.log("viewData.workflowboard_id", props.workflowboard_id ? parseInt(props.workflowboard_id) : viewData.workflowboard_id, generalFilters.workflowboard);
                // console.log("viewData.workflowphase_id", viewData.workflowphase_id, generalFilters.workflowphase);
                // console.log("phaseFilters",phaseFilters);
                // console.log("filters", JSON.parse(JSON.stringify(filters)), filters);

                let url = `${apiHostname}/api/v1.0/workflowcard`;
                if (viewData.workflowcard_id) {
                    url = `${apiHostname}/api/v1.0/workflowcard/${viewData.workflowcard_id}/relatedcards`;
                }

                const tmpData = {
                    "data": []
                };
                getDataWithoutLimit(url, filters, tmpData, "data").then(() => {
                        // Set data when finished loading instead
                        componentData.data = tmpData.data;
                        componentData.loading = false;
                        if (tmpData.data.length) {
                            workflowphasesData.loading = false;
                        }
                        if (workflowphasesData.loading_counter === Object.values(workflowphasesData.workflowphases).length - 1) {
                            workflowphasesData.loading = false;
                            workflowphasesData.noitems = !tmpData.data.length;
                        }
                        workflowphasesData.loading_counter++;
                    }
                );
            });
        };
        let watcher = null;
        onMounted(() => {
            watcher = watch(
                () => generalFilters.workflowboard[props.workflowboard_id ? parseInt(props.workflowboard_id) : viewData.workflowboard_id],
                () => {
                    if (typeof generalFilters.workflowboard[props.workflowboard_id ? parseInt(props.workflowboard_id) : viewData.workflowboard_id] === "undefined") return;
                    getData();
                    watcher && watcher();
                },
                {
                    immediate: true
                }
            );
        });
        onUnmounted(() => {
            watcher && watcher();
        });

		return {
            trans,

			currentLayout,

            componentData,
            viewData,
            sort
		};
	},
});

</script>
