<template>
    <template v-for="item in componentData.workflowboardbutton">
        <template v-if="item.isVisible">
            <div class="Component WorkflowphaseActions">
                <button class="more" @click="handleClick(item)">{{ item.title }}</button>
            </div>
            <Popup v-if="popupVisibility[item.id]" :togglePopup="() => togglePopup(item.id)" classlist="WorkflowcardAdd large">
                <form class="cs_form" ref="addForm" @submit.prevent="handleSubmit($event, componentData.form, item)" v-if="componentData.form">
                    <fieldset>
                        <div class="error" v-if="componentData.error.error">
                            <span class="title">{{ trans("Er lijkt iets mis te zijn met de invoer. Controleer de invoer van alle velden en probeer het opnieuw.") }}</span>
                        </div>
                        <Fields
                            :editing="true"
                            :fields="formFields(componentData.form)"
                            :values="{}"
                        />
                        <nav>
                            <button type="submit" class="more">
                                <span class="title">{{ trans("Verzenden") }}</span>
                            </button>
                        </nav>
                    </fieldset>
                </form>
            </Popup>
            <Popup
                v-if="popupVisibility.customcomponent"
                classlist="Customcomponent Detail"
                :closeButton="true"
                :closeByBackground="false"
                :togglePopup="() => togglePopup('customcomponent')"
            >
                <component
                    v-if="popupData.customcomponent?.data?.component"
                    :is="popupData.customcomponent.data.component"
                    :data="popupData.customcomponent.data"
                    :values="workflowproperties(popupData.customcomponent.item.id)"
                    @handleCustomSubmit="handleCustomComponentSubmitEvent"
                    @handleCustomCancel="togglePopup('customcomponent')"
                />
            </Popup>
        </template>
    </template>
</template>

<script>
import {defineComponent, onMounted, reactive, inject, watch, ref, computed} from "vue";

import { config, apiHostname, env } from "config";

// Helpers
import {getDataAsync, postDataAsync} from "helpers/_api";
import {resolveData} from "helpers/_data";
import trans from "helpers/_translation";

import { workflowproperties } from "store/workflow";
import { usergroup_ids, company } from "store/portal";
import { validateVisibleForUsergroup } from "helpers/_validators";

// Components
import Popup from "components/popup";
import Fields from "components/fields";

import { chainPromiseCalls, chainPromiseCallsWithoutParameters } from "helpers/_promises";

import {setWorkflowpropertyfield} from "helpers/_workflowpropertyfield";

// Custom components
import quoteSetup from "components/quote_setup";

export default defineComponent({
    name: "actions",
    props: {
        workflowboard_id: {
            type: String,
            required: true
        }
    },
    components: {
        Fields,
        Popup,
        quoteSetup
    },
    setup(props) {

        const viewData = inject("viewData");
        const iframe = ref("");
        const addForm = ref();

        const componentData = reactive({
            workflowboardbutton: [],
            workflowform: [],
            form: [],
            error: {
                error: false,
                message: ""
            },
            customButtonComponents: env("custom_button_components") ?? []
        });

        const checkVisibility = () => {
            if (!usergroup_ids.value) return;
            componentData.workflowboardbutton.forEach((item, key) => {
                componentData.workflowboardbutton[key].isVisible = false;
                if (usergroup_ids.value && validateVisibleForUsergroup(item)) {
                    componentData.workflowboardbutton[key].isVisible = true;
                }
            });
        };

        const getWorkflowboardButtonByBoardid = (id = props.workflowboard_id) => {
            if (!id) return;
            if (viewData.workflowboardbuttonbyboardid[id]) {
                componentData.workflowboardbutton = viewData.workflowboardbuttonbyboardid[id];
                checkVisibility();
                return;
            }

            viewData.workflowboardbuttonbyboardid[id] = [];
            getDataAsync(`${apiHostname}${config.value.workflowboardbutton.url}`, [
                [
                    "RelatedFilter",
                    {
                        "linked_ids": [id],
                        "match_all": false,
                        "module": "Workflowboard",
                        "module_link": "Workflowboardbutton"
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ]
            ]).then(result => {
                viewData.workflowboardbuttonbyboardid[id] = resolveData(result);
                componentData.workflowboardbutton  = viewData.workflowboardbuttonbyboardid[id];
                componentData.workflowboardbutton.forEach(button => {
                    fetchForm(button.workflowform_id);
                });
                checkVisibility();
            })
        };

        const fetchForm = (id) => {
            return new Promise((resolve, reject) => {
                if (!id) return reject();
                const target = componentData.workflowform.find(form => `${form.id}` === `${id}`);
                if (target) return resolve(target);
                getDataAsync(`${apiHostname}${config.value.workflowform.url}/${id}`).then(result => {
                    componentData.workflowform.push(result);
                    resolve(result)
                });
            });
        };

        const formFields = (form) => {
            return computed(() => {
                console.log("form", form);
                const fields = [];
                form.extra.workflowformfields.forEach(data => {
                    const field = {
                        ...data.property,
                        extra: {
                            ...data
                        }
                    };
                    if (data?.field_type) {
                        field.type = data.field_type;
                    }
                    delete field.extra.property;
                    fields.push(field);
                });
                console.log("fields",fields);
                return fields;
            }).value;
        };


        const eventCustomComponentAction = (event, actions, target) => {
            return new Promise((resolve, reject) => {
                console.log("target",target);
                popupData.customcomponent.data = actions;
                popupData.customcomponent.event = event;
                popupData.customcomponent.item = target;
                togglePopup("customcomponent");
                // Now watch our popup for closing, if so resolve our promise.
                eventActionsWatching.customcomponent = resolve;
            });
        };

        const handleCustomComponentSuccess = (values) => {
            console.log("SUCCESS", values);
            location.reload();
        };

        const handleCustomComponentSubmitEvent = async (value) => {
            // Set field true if changes made, otherwise fallback to current state
            successCustomcomponentForm = true;
            setWorkflowpropertyfield(popupData.customcomponent.item, popupData.customcomponent.item.data.id, {
                id: popupData.customcomponent.data.property_id,
                type: "JsonField"
            }, value);
            // postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${form.action.id}/execute`, data);
            setTimeout(() => {
                togglePopup('customcomponent');
            }, 200);
        };

        const handleSubmit = (e, target_form, item) => {
            if (!componentData.form) return;

            const form = e.target;
            const formData = new FormData(form);
            postDataAsync(`${apiHostname}${config.value.workflowform.url}/${componentData.form?.data?.id}/submit`, formData).then(result => {

                console.log("RESULT", result);
                // Hook into the proces, since we now have a workflowcard_id, open the customComponentPopup
                const customComponents = resolveCustomComponents(item);
                if (customComponents.length) {
                    togglePopup(item.id);
                    const groupedActions = {};
                    const actionPromises = [];
                    const actionParams = [];
                    customComponents.forEach(component => {
                        component.actions.forEach(customAction => {
                            // Assign our action as parameter for usage with eventCustomComponentAction
                            groupedActions[`custom_action_component_${customAction.property_name}`] = customAction;
                            actionPromises.push(eventCustomComponentAction);
                            actionParams.push([e, groupedActions[`custom_action_component_${customAction.property_name}`], result]);
                        });

                        chainPromiseCalls(actionPromises, actionParams).then(handleCustomComponentSuccess);
                    });
                } else {
                    location.reload();
                }
            }, error => {
                componentData.error.error = true;
                componentData.error.message = error;
            });
        };

        watch(
            () => props.workflowboard_id,
            getWorkflowboardButtonByBoardid,
            {
                immediate: true
            }
        );

        watch(
            () => usergroup_ids.value,
            checkVisibility
        );

        window.addEventListener("message", receiveMessage, false);

        function receiveMessage(event) {
            // Set iframe height based on size
            iframe.value && event.data && (iframe.value.height = event.data + "px");
        };

        // Watchers for promsises.
        let successCustomcomponentForm = false;
        const eventActionsWatching = {
            customcomponent: null
        };

        // Our popup related code, every action_type can have it's own popup with diffent data based on action clicked
        const popupVisibility = reactive({
            add: false,
            customcomponent: false
        });
        const popupData = reactive({
            add: {},
            customcomponent: {}
        });
        const togglePopup = (trigger) => {
            popupVisibility[trigger] = !popupVisibility[trigger];
            popupVisibility[trigger] ? document.body.classList.add("VuePopupOpen") : document.body.classList.remove("VuePopupOpen");
        };


        const handleClick = (button) => {
            fetchForm(button.workflowform_id).then(form => {
                componentData.form = form;
                togglePopup(button.id);
            });
        };


        const resolveCustomComponents = (item) => {
            return computed(() => {
                return componentData.customButtonComponents.filter(a => `${a.workflowboardbutton_id}` === `${item.id}`);
            }).value;
        };

        return {
            trans,
            iframe,
            viewData,
            componentData,
            workflowproperties,

            formFields,
            handleSubmit,
            addForm,

            // Popup related variables
            popupVisibility,
            popupData,
            togglePopup,

            handleClick,

            resolveCustomComponents,
            handleCustomComponentSubmitEvent
        };
    },
});
</script>

<style>
.iframe-container {
    max-height: calc((100vh - (var(--padding-large) * 2) - 20px));
    overflow-y: auto;
}
</style>