<template>
    <div id="quote-setup" :class="{'loading' : componentData.loading}">
        <div class="left">
            <div class="Heading">
                <Icon name="filter" />
                <div class="h3">{{ trans("Filters") }} </div>
            </div>
            <div class="element searchField">
                <input type="text" ref="searchRef" @keyup="handleFilter()" placeholder="Search" />
            </div>
            <template v-for="refine in customComponentData.settings.refine_configuration">
                <div class="element CheckboxField">
                    <div ref="dropdown" class="dropdown">
                        <button class="btn dropdown-toggle" type="button" @click.dropdown="toggleDropdown(refine)">
                            <span class="title">{{ refine.title }}</span>
                        </button>
                        <div class="dropdown-menu" :class="{'open' : isDropdownOpen(refine)}">
                            <div class="Checkbox_container">
                                <div class="Checkbox_element" v-for="(option, key) in options(refine)">
                                    <input
                                        type="checkbox"
                                        class="checkbox"
                                        :name="field_name(refine)"
                                        :id="`${field_name(refine)}_${option}`"
                                        :value="key"
                                        :checked="checked(key,refine)"
                                    />
                                    <label :for="`${field_name(refine)}_${option}`" class="label" @click.prevent="handleChange($event, option, refine)">
                                        <span class="title">{{ option }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="middle">
            <table width="100%">
                <thead>
                <tr>
                    <th class="td_code" align="left">{{ trans("Code") }}</th>
                    <th class="td_type" align="left">{{ trans("Type") }}</th>
                    <th class="td_name" align="left">{{ trans("Omschrijving") }}</th>
                    <th class="td_amount" align="left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in componentData.items" :key="index">
                    <td class="td_code"><span class="title">{{ getValue("code",item) }}</span></td>
                    <td class="td_type"><span class="title">{{ getValue("type",item) }}</span></td>
                    <td class="td_name">
                        <span class="title">{{ getValue("name",item) }}</span>
                        <template v-if="getValue('explanation',item)">
                            <TooltipElement :tooltip_information="getValue('explanation',item)" />
                        </template>
                    </td>
                    <td class="td_amount">
                        <div class="amount">
                        <input type="number" v-model.number="item.quantity">
                        <button class="button" type="button" @click="addToSelected(item)">
                            <Icon name="add"></Icon>
                        </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <Pagination
                :id="settings.id"
                :pagination_key="PAGE_KEY"
                :pagination_total="settings.total"
                :pagination_limit="settings.limit"
                :pagination_current="settings.current"
                @updatePage="handlePagination"
            />
        </div>
        <div class="right">
            <div class="Heading">
                <div class="h3">{{ trans("Product list") }} </div>
            </div>
            <table width="100%" cellpadding="0" cellspacing="0" v-if="componentData.selectedItems.length > 0">
                <thead>
                <tr>
                    <th class="td_amount" align="left"></th>
                    <th class="td_code" align="left">{{ trans("Code") }}</th>
                    <th class="td_name" align="left">{{ trans("Omschrijving") }}</th>
                    <th class="td_icons" align="left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in componentData.selectedItems">
                    <td class="td_amount"><span class="title">{{ item.quantity }}<small style="padding-left:1px;">x</small></span></td>
                    <td class="td_code"><span class="title">{{ item.code }}</span></td>
                    <td class="td_name"><span class="title">{{ item.description }}</span></td>
                    <td class="td_icons" align="right">
                        <button type="button" @click="removeFromSelected(item)">
                            <Icon name="delete"></Icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-else>
                <span class="noproducts">{{ trans("Nog geen items toegevoegd") }}</span>
            </div>
            <div class="element TextareaField" v-if="componentData.selectedItems.length">
                <label for="notes">
                    <span class="title">{{ trans("Opmerkingen") }}</span>
                </label>
                <textarea class="input textarea" id="notes" v-model="componentData.notes"></textarea>
            </div>
            <nav>
                <button type="button" class="more" @click.prevent="handleSubmit">
                    <span class="title">{{ trans("Offerte aanvragen") }}</span>
                </button>
            </nav>
        </div>
    </div>
</template>

<script>
import {defineComponent, reactive, ref, watch, onMounted, inject, computed} from "vue";

import trans from "helpers/_translation";
import Icon from "components/icon";
import Popup from "components/popup";
import { config, env, apiHostname } from "config";
import { getData, getDataAsync, getDataWithoutLimit } from "helpers/_api";
import Pagination from "js/components/pagination.vue";

import { addNotification } from "store/notifications";

import {hasQuery, getQuery, setQuery, getUrlWithQueryItemReplaced} from "helpers/_structure";

import TooltipElement from "formbuilder/elements/TooltipElement.vue";

export default defineComponent({
    name: "Quote Setup",
    props: {
        data: {
            type: Object,
            required: true
        },
        values: {
            type: Object,
            required: false
        }
    },
    components: {
        Icon,
        Popup,
        Pagination,
        TooltipElement
    },
    emits: ["handleCustomSubmit", "handleCustomCancel"],
    setup(props, { emit }) {

        // Define our parameters
        const componentData = reactive({
            items: [],
            totals: 0,
            loading: false,
            notes: "",
            selectedItems: [],
            refines: []
        });

        const filtersData = reactive({});

        const searchRef = ref();
        const amountRef = ref();

        const PAGE_KEY = props.module_object === "Product" ? "page" : `page_${props.id}`;

        // Set settings and watch for props changes
        const settings = reactive({
            loading: true,
            current: hasQuery(PAGE_KEY) ? getQuery(PAGE_KEY) : 1,
            limit: 10,
            total: computed(() => componentData.totals),
            sortby: {
                seq: getQuery("seq") ?? null,
                order: getQuery("order") ?? null
            },
            popover: {
                open: false
            },
            template: {}
        });

        const handlePagination = (page, force = false) => {
            if (`${settings.current}` === `${page}` && !force) return;
            settings.current = page;
            //scrollToTop();
            fetchData();
        };

        // Fetch the data
        const appendUrlDefaults = (url) => {
            url = getUrlWithQueryItemReplaced("page", settings.current, url);
            ["seq", "order"].forEach(key => {
                if (!settings.sortby[key]) return;
                url = getUrlWithQueryItemReplaced(key, settings.sortby[key], url);
            });
            return url;
        };

        const customComponentData = computed(() =>  {
            return props.data;
        });


        const refine_filters = computed(() => {
            const result = [];

            // Loop through our refine
            Object.keys(filtersData).forEach(key => {
                const id = key.replace("field_", "");
                const property = optionData[`${id}`];
                if (!filtersData[key].length) return;
                result.push([
                    "StudioIbizz\\Workflowproperty\\Filters\\Workflowproperty",
                    {
                        "column": property.name,
                        "value": filtersData[key]
                    }
                ]);
            });

            return result;
        });
        const fetchData = () => {
            componentData.items = [];
            componentData.loading = true;


            const url = appendUrlDefaults(`${apiHostname}/api/v1.0/workflowcard?limit=${settings.limit}`);

            const extra_filters = customComponentData.value.settings.extra_filters;
            const selected_filters = refine_filters.value ?? [];
            getData(url, [
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ],
                ...extra_filters,
                ...selected_filters
            ]).then(result => {
                componentData.items = result.data;
                componentData.totals = result._meta.total;
                setTimeout(() => {
                    componentData.loading = false;
                }, 250);
            });
        }

        const optionData = reactive({});
        const options = (refine) => {
            return computed(() => {
                if (!(`${refine.property_id}` in optionData)) {
                    optionData[`${refine.property_id}`] = [];
                    getDataAsync(`${apiHostname}/api/v1.0/workflowpropertyfield/${refine.property_id}`).then(result => {
                        optionData[`${refine.property_id}`] = result.data;
                    });
                }

                try {
                    const optionJson = optionData[`${refine.property_id}`].options_json;
                    if (!optionJson) {
                        return {};
                    }
                    const parsedOptionJson = JSON.parse(optionJson);
                    return parsedOptionJson;
                } catch (error) {
                    console.error("Failed to parse JSON:", error.message);
                }
                return {};
            }).value;
        };

        const getValue = (key = "name", item) => {
            const keyMap = {
                "name": 92,
                "code": 90,
                "type": 91,
                "explanation": 121
            };
            return computed(() => {
                console.log("item",item);
                if (key in keyMap) {
                    return item?.extra?.workflowproperties.find((property) => property.field.id == keyMap[key])?.value ?? "";
                }
                return "";
            }).value;
        };

        const getCode = (item) => {
            return computed(() => {
                if (item?.extra?.workflowproperties.find((property) => property.field.id == 90)) {
                    return item?.extra?.workflowproperties.find((property) => property.field.id == 90).value ?? "";
                } else{
                    return "";
                }
            }).value;
        };

        const getType = (item) => {
            return computed(() => {
                if (item?.extra?.workflowproperties.find((property) => property.field.id == 91)) {
                    return item?.extra?.workflowproperties.find((property) => property.field.id == 91).value ?? "";
                } else{
                    return "";
                }
            }).value;
        };

        const getName = (item) => {
            return computed(() => {
                if (item?.extra?.workflowproperties.find((property) => property.field.id == 92)) {
                    return item?.extra?.workflowproperties.find((property) => property.field.id == 92).value ?? "";
                } else{
                    return "";
                }
            }).value;
        };

        const getNote = (item) => {
            return computed(() => {
                if (item?.extra?.workflowproperties.find((property) => property.field.id == 121)) {
                    return item?.extra?.workflowproperties.find((property) => property.field.id == 121).value ?? "";
                } else{
                    return "";
                }
            }).value;
        };

        const handleFilter = () => {
            const search = searchRef.value.value.toLowerCase();
            if (search) {
                if (search.length > 2){
                    componentData.items = componentData.items.filter((item) => {
                        return getValue("code",item).toLowerCase().includes(search) || getValue("type",item).toLowerCase().includes(search) || getValue("name",item).toLowerCase().includes(search);
                    });
                    componentData.totals = componentData.items.length;
                }
            } else {
                fetchData();
            }
        };

        const addToSelected = (item) => {
            const quantityToAdd = item.quantity || 1; // Default to 1 if no value is entered

            // Check if the item is already in selectedItems
            const existingItem = componentData.selectedItems.find(selectedItem => {
                return selectedItem.code === getValue("code",item);
            });

            if (existingItem) {
                existingItem.quantity += quantityToAdd; // Increase the amount by the entered quantity
            } else {
                componentData.selectedItems.push({
                    quantity: quantityToAdd,
                    code: getValue("code",item),
                    description: getValue("name",item)
                });
                item.quantity = "";
            }
        };

        const removeFromSelected = (item) => {
            componentData.selectedItems = componentData.selectedItems.filter((selectedItem) => selectedItem != item);
        };

        onMounted(() => {
            fetchData();
        });

        const handleSubmit = (e) => {
            if (!componentData.selectedItems.length) {
                addNotification(trans("Selecteer alsjeblieft een %s van de lijst", trans("item")));
                return;
            }

            const json = {
                items: componentData.selectedItems,
                notes: componentData.notes
            };

            emit("handleCustomSubmit", JSON.stringify(json));
        };

        const dropdownData = reactive({});

        const isDropdownOpen  = (refine) => {
            return computed(() => {
                if (refine.open && !(`${refine.property_id}` in dropdownData)) {
                    dropdownData[`${refine.property_id}`] = true;
                }
                return dropdownData[`${refine.property_id}`] ?? false;
            }).value;
        };
        const toggleDropdown = (refine) => {
            dropdownData[`${refine.property_id}`] = !isDropdownOpen(refine);
        };

        const predefined_values = computed(() => {
            return [];
        });

        // computed to define if it should be checked or not
        const checked = (key,refine) => {
            return computed(() => {
                const name = field_name(refine);
                return filtersData[name]?.includes(`${key}`);
            }).value;
        }
        const field_name = (refine) => {
            return computed(() => {
                return `field_${refine.property_id}`;
            }).value;
        }

        const handleChange = (e, key, refine) => {
            const name = field_name(refine);

            if (!filtersData[name]) {
                filtersData[name] = [];
            }
            if (checked(key,refine)) {
                filtersData[name] = filtersData[name].filter(value => `${value}` !== `${key}`);
            } else {
                if (props.type === "radio") {
                    filtersData[name] = [`${key}`];
                } else {
                    filtersData[name].push(`${key}`);
                }
            }
        };

        watch(
            () => filtersData,
            () => {
                fetchData();
            },
            {
                deep: true
            }
        );


        watch(
            () => props.values,
            () => {
                if (!customComponentData.value?.settings?.filled_by_property_name) return;
                const targetValue = Object.values(props.values.value).find(a => a.field.name === customComponentData.value.settings.filled_by_property_name);
                if (targetValue) {
                    componentData.selectedItems = targetValue.value.items;
                    componentData.notes = targetValue.value.notes;
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        return {
            trans,

            componentData,

            settings,
            handlePagination,

            addToSelected,
            removeFromSelected,
            handleFilter,

            searchRef,
            amountRef,

            getValue,

            handleSubmit,

            options,
            customComponentData,

            isDropdownOpen,
            toggleDropdown,
            handleChange,
            field_name,
            checked
        };
    },
});
</script>