<template>
    <template v-if="!this.workflowevent_id && events.length" v-for="item in events">
        <button type="button" class="more outline" :class="item.event_key" @click="handleEvent($event, item)">
            <span class="title">{{ item.title }}</span>
        </button>
    </template>
    <Popup
        v-if="popupVisibility.customcomponent"
        classlist="Customcomponent Detail"
        :closeButton="true"
        :closeByBackground="false"
        :togglePopup="() => togglePopup('customcomponent')"
    >
        <component
            v-if="popupData.customcomponent?.data?.component"
            :is="popupData.customcomponent.data.component"
            :data="popupData.customcomponent.data"
            :values="workflowproperties(viewData.workflowcard_id)"
            @handleCustomSubmit="handleCustomComponentSubmitEvent"
            @handleCustomCancel="togglePopup('customcomponent')"
        />
    </Popup>
    <Popup v-if="popupVisibility.confirm" :closeButton="false" :togglePopup="() => togglePopup('confirm')"
           classlist="Confirm Detail small justified">
        <div class="Heading">
            <h1 class="h1">{{ trans("Wilt u deze actie uitvoeren?") }}</h1>
        </div>
        <div class="inner">
            <nav>
                <button type="button" class="more" @click.prevent="handleConfirmClick">
                    <span class="title">{{ trans("Ja") }}</span>
                </button>
                <button type="button" class="more outline" @click.prevent="togglePopup('confirm')">
                    <span class="title">{{ trans("Annuleren") }}</span>
                </button>
            </nav>
        </div>
    </Popup>
    <Popup v-if="popupVisibility.workflowform" :togglePopup="() => togglePopup('workflowform')"
           classlist="Workflowform Detail large">
        <template v-for="form in popupData.workflowform.data">
            <div class="Heading">
                <h1 class="h1">{{ form.data.title }}</h1>
            </div>
            <div class="inner">
                <form class="cs_form" @submit.prevent="handleWorkflowformSubmitEvent($event, form)">
                    <Fields
                        :fields="formFields(form)"
                        :values="appendPortalValues(formFields(form), formValues(form))"
                    />
                    <nav>
                        <button type="submit" class="more outline">
                            <span class="title">{{ trans("Versturen") }}</span>
                        </button>
                    </nav>
                </form>
            </div>
        </template>
    </Popup>
    <Popup v-if="popupVisibility.workflowproperty" :togglePopup="() => togglePopup('workflowproperty')"
           classlist="Workflowproperty Overview large">
        <div class="Heading">
            <h1 class="h1">{{ trans("Maak uw keuze%s", popupData.workflowproperty.data.length > 1 ? 's' : '') }}</h1>
        </div>
        <div class="inner">

            <form class="cs_form" @submit.prevent="handleWorkflowpropertySubmitEvent">
                <Fields
                    :fields="popupData.workflowproperty.data"
                    :values="appendPortalValues(popupData.workflowproperty.data, workflowproperties(viewData.workflowcard_id))"
                />
                <nav>
                    <button type="submit" class="more outline">
                        <span class="title">{{ trans("Opslaan") }}</span>
                    </button>
                </nav>
            </form>
        </div>
    </Popup>
    <Popup v-if="popupVisibility.emailnotification" :togglePopup="() => togglePopup('emailnotification')"
           classlist="Emailnotification Overview large">
        <div class="Heading">
            <h1 class="h1">{{
                    trans("Stel uw emailnotificatie%s samen", popupData.emailnotification.data.length > 1 ? 's' : '')
                }}</h1>
        </div>
        <div class="inner">
            <form class="cs_form" @submit.prevent="handleEmailnotificationSubmitEvent"
                  v-for="item in popupData.emailnotification.data"
                  :set="templates = resolveEmailnotificationTemplates(item)">
                <fieldset>
                    <legend v-if="popupData.emailnotification.data.length > 1">{{ item.title }}</legend>
                    <input type="hidden" name="id" :value="item.id"/>
                    <template v-if="templates.length === 1">
                        <input type="hidden" name="emailnotification_id" :value="templates[0].id"/>
                    </template>
                    <template v-else-if="templates.length > 1">
                        <div class="element SelectField">
                            <label>{{ trans("Selecteer een template") }}</label>
                            <select name="emailnotification_id" @change="handleEmailnotificatioSelection($event, item)">
                                <option value="">{{ trans("- Maak uw keuze -") }}</option>
                                <option v-for="template in templates" :value="template.id">
                                    {{ template.title }}
                                </option>
                            </select>
                        </div>
                    </template>

                    <template v-if="item.selected" :key="item.selected">
                        <div :set="template = templates.find(a => `${a.id}` === `${item.selected}`)">
                            <input type="hidden" name="subject" readonly :value="template.subject">
                            <hr>
                            <div class="element EmailField">
                                <label>{{ trans("Afzender") }}</label>
                                <input class="input" type="text" disabled
                                       :value="resolveSendValue(item.workflowemailnotification_from_name, item.workflowemailnotification_from_email)">
                            </div>
                            <hr>
                            <div class="element EmailField">
                                <label>{{ trans("Aan") }}</label>
                                <input class="input" type="text" disabled
                                       :value="resolveSendValue(item.workflowemailnotification_to_name, item.workflowemailnotification_to_email)">
                                <input type="hidden" readonly name="email_to"
                                       :value="item.workflowemailnotification_to_email">
                            </div>
                            <template v-for="(ccEmail, ccIndex) in componentData.ccAddressFields" :key="ccIndex">
                                <div class="element EmailField">
                                    <label :for="resolveInputName(item, 'email_cc_' + ccIndex)"
                                           v-if="ccIndex === 0">{{ trans("CC") }}</label>
                                    <label :for="resolveInputName(item, 'email_cc_' + ccIndex)" v-if="ccIndex > 0">&nbsp;</label>
                                    <input class="input" type="email" :name="'email_cc[]'"
                                           :id="resolveInputName(item, 'email_cc_' + ccIndex)"
                                           @blur="addCcEmailField(ccIndex, $event.target.value)"/>
                                </div>
                            </template>
                            <template v-for="(bccEmail, bccIndex) in componentData.bccAddressFields" :key="bccIndex">
                                <div class="element EmailField">
                                    <label :for="resolveInputName(item, 'email_bcc_' + bccIndex)" v-if="bccIndex === 0">{{
                                            trans("BCC")
                                        }}</label>
                                    <label :for="resolveInputName(item, 'email_bcc_' + bccIndex)" v-if="bccIndex > 0">&nbsp;</label>
                                    <input class="input" type="email" :name="'email_bcc[]'"
                                           :id="resolveInputName(item, 'email_bcc_' + bccIndex)"
                                           @blur="addBccEmailField(bccIndex, $event.target.value)"/>
                                </div>
                            </template>
                            <hr>
                            <EditorField :label="trans('Inhoud')" :editing="popupData.emailnotification.allow_edit"
                                         :readonly="!popupData.emailnotification.allow_edit" name="content"
                                         :value="template.content"/>
                            <small class="note">
                                <p><strong>{{ trans("Let op:") }}</strong>
                                    {{
                                        trans("Dit automatisch gegenereerde bericht maakt gebruik van berichtvariabelen. Dit zijn de codes die in de tekst worden weergegeven tussen de tekens [% … %]. Op de plek van deze codes wordt in de uitgaande mail de beschikbare informatie gevuld. Je kunt alle uitgaande mails zelf nog aanpassen of aanvullen.")
                                    }}
                                </p>
                            </small>
                            <template v-if="componentData.attachments">
                                <hr>
                                <CheckboxField name="attachment_ids[]" :float="false"
                                               :label="trans('Selecteer bijlage')" :options_json="attachments_json"/>
                            </template>
                        </div>
                    </template>
                </fieldset>
            </form>
            <nav>
                <button type="button" class="more outline" @click.prevent="handleEmailnotificationSkipEvent">
                    <span class="title">{{ trans("Verder zonder mail te verzenden") }}</span>
                </button>
                <button type="button" class="more" @click="handleEmailnotificationSubmitEvent">
                    <span class="title">{{ trans("Verzenden") }}</span>
                </button>
            </nav>
        </div>
    </Popup>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, reactive, inject, watch, computed, ref} from "vue";
import {useRouter} from "vue-router";

// Helper functions
import {getDataAsync, getDataWithoutLimit, postDataAsync} from "helpers/_api";
import {mergeDeep} from "helpers/_objects";

import {formatData, resolveData, resolveUrl} from "helpers/_data";
import {setWorkflowpropertyfield} from "helpers/_workflowpropertyfield";
import {validateVisibleForUsergroup} from "helpers/_validators";
import {addHistorylogRecord} from "helpers/_historylog";
import trans from "helpers/_translation";

import {config, apiHostname, env} from "config";

import {contact, value, usergroup_ids, fetchItem, item} from "store/portal";
import {workflowStore, workflowproperties} from "store/workflow";

import {addNotification} from "store/notifications";

// Components
import Popup from "components/popup";
import Fields from "components/fields";
import quoteSetup from "components/quote_setup";

import EditorField from "components/elements/EditorField.vue";
import CheckboxField from "components/elements/CheckboxField.vue";

import { chainPromiseCalls, chainPromiseCallsWithoutParameters } from "helpers/_promises";

export default defineComponent({
    name: "actions",
    props: {
        "workflowevent_id": {
            type: Number,
            required: false
        }
    },
    components: {
        Fields,
        Popup,
        EditorField,
        CheckboxField,
        quoteSetup
    },
    emits: ["success"],
    setup(props, { emit }) {
        const router = useRouter();

        const viewData = inject("viewData");
        const isCommunity = inject("isCommunity");
        const goToOverview = inject("goToOverview");

        const getItems = (module_access, id) => {
            return new Promise((resolve, reject) => {
                let apiUrl = `${apiHostname}/${config.value[module_access].url}`;
                // If ID exists, pass it to the url
                id && (apiUrl = `${apiUrl}/${id}`);
                const stateFilters = [
                    [
                        "FieldFilter",
                        {
                            "column": "state",
                            "value": "Online"
                        }
                    ]
                ];
                getDataAsync(`${apiUrl}?limit=100`, stateFilters).then(result => {
                    resolve(id ? {
                        extra: (result.extra ?? []),
                        _allow: (result._allow ?? []),
                        ...result.data
                    } : formatData(result));
                });
            });
        };
        const getItemsAndStore = (module_access, id) => {
            return new Promise((resolve, reject) => {
                if (Object.values(workflowStore[module_access]?.data ?? {}).length) {
                    resolve(workflowStore[module_access]);
                    return;
                }
                getItems(module_access, id).then(result => {
                    workflowStore[module_access] = mergeDeep(workflowStore[module_access], result);
                    resolve(workflowStore[module_access]);
                });
            });
        };

        // Our popup related code, every action_type can have it's own popup with diffent data based on action clicked
        const popupVisibility = reactive({
            customcomponent: false,
            workflowproperty: false,
            workflowform: false,
            emailnotification: false,
            confirm: false
        });
        const popupData = reactive({
            customcomponent: {},
            workflowproperty: {},
            workflowform: {},
            emailnotification: {}
        });
        const togglePopup = (trigger) => {
            popupVisibility[trigger] = !popupVisibility[trigger];
            popupVisibility[trigger] ? document.body.classList.add("VuePopupOpen") : document.body.classList.remove("VuePopupOpen");
        };
        const resetPopup = () => {
            for (const [key, value] of Object.entries(popupVisibility)) {
                popupVisibility[key] = false;
            }
        };

        const componentData = reactive({
            events: {},
            ccAddressFields: [''],
            bccAddressFields: [''],
            attachments: [],
            customActionComponents: env("custom_action_components") ?? []
        });

        const resolvePropertyfieldByKey = (key, value) => {
            const properties = viewData.item.data?.extra?.workflowproperties ?? [];
            const property = properties.find(a => `${a.field[key]}` === `${value}`);
            if (property) {
                return property;
            }
            return {
                field: item("workflowpropertyfield", value)?.data,
                value: ""
            };
        };
        const resolveFields = (data) => {
            // Return fields based on properties passed
            const fields = [];
            // Get the field id's and return field data
            data.map(x => x.workflowpropertyfield_id).forEach(id => {
                const field = resolvePropertyfieldByKey("id", id);
                field && fields.push(field);
            });
            return fields;
        };

        // Used to store our promises for when full process is followed
        let successPromises = [];
        // Boolean is used to check if form was successfull send, if not we don't handle successPromises
        let successCustomcomponentForm = false;
        let successWorkflowpropertyForm = false;
        let successWorkflowformForm = false;
        let successEmailnotificationForm = false;
        const eventActionsWatching = {
            customcomponent: null,
            workflowproperty: null,
            workflowform: null,
            emailnotification: null
        };

        const action = (event, actions, item, successPromises, moduleData) => {
            return new Promise((resolve, reject) => {
                // Notification
                const formData = new FormData();
                formData.set("workflowcard_id", moduleData.workflowcard_id);
                successPromises.push(() => {
                    return new Promise(async (res) => {
                        for (const action of actions) {
                            await postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${action.id}/execute`, formData);
                        }
                        res();
                    });
                });
                resolve();
            });
        };


        const eventCustomComponentAction = (event, actions, target) => {
            return new Promise((resolve, reject) => {
                popupData.customcomponent.data = actions;
                popupData.customcomponent.event = event;
                togglePopup("customcomponent");
                // Now watch our popup for closing, if so resolve our promise.
                eventActionsWatching.customcomponent = resolve;
            });
        };
        const eventActionsSorted = {
            workflowproperty: (event, actions, target) => {
                return new Promise((resolve, reject) => {
                    const automated = actions.filter(action => action.trigger === "auto");
                    const manual = actions.filter(x => !automated.includes(x));
                    automated.forEach(action => {
                        // 1. Automated
                        const field_id = action.workflowpropertyfield_id;
                        const field_value = action.workflowproperty_value;
                        // Set the value
                        successPromises.push(() => {
                            const formData = new FormData();
                            formData.set("workflowcard_id", viewData.workflowcard_id);
                            postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${action.id}/execute`, formData).then(result => {
                                // Now we store our result in our workflowcardData again
                                const property = viewData.item?.data?.extra?.workflowproperties?.find(a => `${a.id}` === `${field_id}`);
                                property && Object.assign(property, result.data.workflowproperty_current_value);
                            });
                            //
                        });
                    });

                    if (manual.length) {
                        // 2. Manual
                        popupData.workflowproperty.data = [];
                        const fieldPromises = [];
                        manual.forEach(a => {
                            fieldPromises.push(new Promise(resolve => {
                                if (!a.workflowpropertyfield_id) {
                                    return resolve(false);
                                }

                                fetchItem("workflowpropertyfield", a.workflowpropertyfield_id).then(result => {
                                    if (!result.data.version_date) return resolve(false);
                                    resolve(true);
                                });
                            }));
                        });
                        Promise.all(fieldPromises).then(() => {
                            popupData.workflowproperty.data = resolveFields(manual).map(a => {
                                return {
                                    ...a,
                                    ...a.field,
                                    ...item("workflowpropertyfield", a.field.id).data
                                };
                            });
                        });

                        popupData.workflowproperty.event = event;
                        togglePopup("workflowproperty");
                        // Now watch our popup for closing, if so resolve our promise.
                        eventActionsWatching.workflowproperty = resolve;
                    } else {
                        setTimeout(() => {
                            resolve();
                        }, 100);
                    }
                });
            },
            workflowform: (event, actions, target) => {
                return new Promise((resolve, reject) => {
                    const forms = [];
                    const formPromises = [];
                    actions.forEach(action => {
                        formPromises.push(() => {
                            return new Promise(res => {
                                getDataAsync(`${apiHostname}/${config.value.workflowform.url}/${action.workflowform_id}`).then(form => {
                                    form.action = action;
                                    forms.push(form);
                                    res();
                                });
                            });
                        })
                    });
                    chainPromiseCallsWithoutParameters(formPromises).then(() => {
                        popupData.workflowform.data = forms;
                        popupData.workflowform.event = event;
                        togglePopup("workflowform");
                        // Now watch our popup for closing, if so resolve our promise.
                        eventActionsWatching.workflowform = resolve;
                    });
                });
            },
            notification: (event, actions, target) => {
                return new Promise((resolve, reject) => {
                    // Notification
                    const formData = new FormData();
                    formData.set("workflowcard_id", viewData.workflowcard_id);
                    successPromises.push(() => {
                        return new Promise((res) => {
                            actions.forEach(action => {
                                postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${action.id}/execute`, formData);
                            });
                            res();
                        });
                    });
                    resolve();
                });
            },
            emailnotification: (event, actions, target) => {
                return new Promise((resolve, reject) => {
                    const automated = actions.filter(action => action.trigger === "auto");
                    const manual = actions.filter(x => !automated.includes(x));
                    // 1. Automated
                    successPromises.push(() => {
                        return new Promise((res) => {
                            automated.forEach(action => {
                                action.extra.workflowemailnotification.forEach(emailnotification => {
                                    // We loop through every template selected, sicne they all should be posted when Automatic is set
                                    const formData = new FormData();
                                    formData.set("workflowcard_id", viewData.workflowcard_id);
                                    formData.set("workflowemailnotification_id", emailnotification.id);
                                    postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${action.id}/execute`, formData);
                                });
                            });
                            res();
                        });
                    });

                    // 2. Manual mail template
                    if (manual.length) {
                        // 2. Manual
                        popupData.emailnotification.data = manual;
                        popupData.emailnotification.event = event;
                        popupData.emailnotification.allow_edit = (target.edit_emailnotification !== "N");
                        togglePopup("emailnotification");
                        // Now watch our popup for closing, if so resolve our promise.
                        eventActionsWatching.emailnotification = resolve;
                    } else {
                        setTimeout(() => {
                            resolve();
                        }, 100);
                    }
                });
            },
            url: (event, actions, target) => {
                return new Promise((resolve, reject) => {
                    successPromises.push(() => {
                        return new Promise((res) => {
                            actions.forEach(action => {
                                const url = resolveUrl(action);
                                url && setTimeout(() => {
                                    window.open(url, action.target);
                                }, 750);
                            });
                            res();
                        });
                    });
                    resolve();
                });
            },
            adduser: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            },
            addcard: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            },
            cardpriority: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            },
            cardtype: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            },
            cardresponsible: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            },
            generatepdf: (event, actions, target) => {
                return action(event, actions, target, successPromises, viewData);
            }
        };

        // Watcher for popup visibility, this allows us to promise resolve only after popup is closed.
        watch(
            () => popupVisibility.customcomponent,
            (newValue) => {
                // Watch for popup close then resolve our promise
                if (newValue) return;
                for (const [key, callbackFunction] of Object.entries(eventActionsWatching)) {
                    // Popup is closed and we have a callbackFunction
                    successCustomcomponentForm && callbackFunction && callbackFunction();
                    setTimeout(() => {
                        eventActionsWatching[key] = null; // Reset
                        successCustomcomponentForm = false;
                    }, 500);
                }
            }
        );
        watch(
            () => popupVisibility.workflowproperty,
            (newValue) => {
                // Watch for popup close then resolve our promise
                if (newValue) return;
                for (const [key, callbackFunction] of Object.entries(eventActionsWatching)) {
                    // Popup is closed and we have a callbackFunction
                    successWorkflowpropertyForm && callbackFunction && callbackFunction();
                    eventActionsWatching[key] = null; // Reset
                }
                successWorkflowpropertyForm = false;
            }
        );
        watch(
            () => popupVisibility.workflowform,
            (newValue) => {
                // Watch for popup close then resolve our promise
                if (newValue) return;
                for (const [key, callbackFunction] of Object.entries(eventActionsWatching)) {
                    // Popup is closed and we have a callbackFunction
                    if (successWorkflowformForm && callbackFunction) {
                        callbackFunction();
                    }
                    setTimeout(() => {
                        eventActionsWatching[key] = null; // Reset
                        successWorkflowformForm = false;
                    }, 500);
                }
            }
        );
        watch(
            () => popupVisibility.emailnotification,
            (newValue) => {
                // Watch for popup open, and load data if not exists
                if (!newValue) {
                    componentData.attachments = [];
                    return;
                }

                getDataAsync(`${apiHostname}/${config.value.upload.url}`, [
                    [
                        "RelatedFilter",
                        {
                            "linked_ids": [viewData.workflowcard_id],
                            "match_all": false,
                            "module": "Workflowcard",
                            "module_link": "Upload"
                        }
                    ],
                    [
                        "FieldFilter",
                        {
                            "column": "state",
                            "value": "Online"
                        }
                    ]
                ]).then(result => {
                    componentData.attachments = resolveData(result);
                });
            }
        );
        const attachments_json = computed(() => {
            const result = {};
            componentData.attachments.forEach(a => {
                result[a.id] = a.title;
            });
            return JSON.stringify(result);
        });

        const resolveGroupedActions = (actions) => {
            const groupedActions = {};
            // Now group our actions based on its values
            resolveData(actions).forEach(action => {
                groupedActions[action.action_type] = groupedActions[action.action_type] || [];
                groupedActions[action.action_type].push(action);
            });
            return groupedActions;
        };
        const resolveCustomComponents = (item) => {
            return computed(() => {
                return componentData.customActionComponents.filter(a => `${a.workflowaction_id}` === `${item.id}`);
            }).value;
        };
        const isManualEmailnotification = (groupedActions) => {
            return groupedActions.emailnotification?.some(a => a.trigger === "manual") ?? false;
        };
        const isConfirm = (groupedActions, item) => {
            // If workflowevent_id was passed we don't want to show confirm
            if (props.workflowevent_id) {
                return false;
            }
            // If custom actions are set, make sure confirm is false
            if (Array.from(resolveCustomComponents(item)).some(a => a?.actions?.length)) {
                return false;
            }

            // If there is a workflowform it should disable the confirm since the user has to finish the proces first anyway.
            // For now "emailnotification" isn't disabled since it doesn't have a confirm yet
            const disableConfirmBy = [
                "workflowform"
            ];
            // If there are workflowproperties who are set manually, we wan't to skip the confirm too
            if (groupedActions.workflowproperty && Object.values(groupedActions.workflowproperty).some(el => el.trigger === "manual")) {
                disableConfirmBy.push("workflowproperty");
            }
            return !(Object.keys(groupedActions).some(r => disableConfirmBy.includes(r)));
        };
        const handleEventSuccess = (values) => {
            // Only show these notifications when it are manual triggered actions, so no direct event action
            if (!props.workflowevent_id) {
                const fullname = value(contact.value, env("variables.contact.properties.fullname"));
                if (fullname) {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("#fullname# heeft de actie “#action#” uitgevoerd."),
                        " ",
                        {
                            fullname: fullname,
                            action: callbackProperties.item?.title
                        }
                    );
                } else {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("De actie “#action#” is uitgevoerd."),
                        " ",
                        {
                            action: callbackProperties.item?.title
                        }
                    );
                }
                addNotification(trans("De wijzigingen zijn succesvol opgeslagen."), 3500);
            }
            resetPopup();
            setTimeout(() => {
                viewData.ticker += 1;
                emit("success");
                goToOverview();
            }, 750);
        };
        const handleEventCallback = ({e, groupedActions, item}) => {
            successPromises = [];
            const actionPromises = [];
            const actionParams = [];

            // Check for custom actions
            resolveCustomComponents(item).forEach(component => {
                component.actions.forEach(customAction => {
                    // Assign our action as parameter for usage with eventCustomComponentAction
                    groupedActions[`custom_action_component_${customAction.property_name}`] = customAction;
                    actionPromises.push(eventCustomComponentAction);
                    actionParams.push([e, groupedActions[`custom_action_component_${customAction.property_name}`], item]);
                })
            });

            // Now we build a array of promises that should be runned sequential, one after the other
            for (const [key, targetFunction] of Object.entries(eventActionsSorted)) {
                if (groupedActions[key]) {
                    // Build an array with functions and parameters it should use
                    actionPromises.push(targetFunction);
                    actionParams.push([e, groupedActions[key], item]);
                }
            }
            chainPromiseCalls(actionPromises, actionParams).then(() => {
                chainPromiseCallsWithoutParameters(successPromises).then(handleEventSuccess);
            });
        };
        let callbackProperties = {};
        const handleConfirmClick = () => {
            handleEventCallback(callbackProperties);
        };

        const handleEvent = (e, item) => {
            // Get our actions for the clicked event specific
            getDataAsync(`${apiHostname}/${config.value.workflowevent.url}/${item.id}/actions`).then(actions => {
                const groupedActions = resolveGroupedActions(actions);

                // Define our properties
                callbackProperties = {e, groupedActions, item};
                // Show confirm or just handle the events
                if (isConfirm(groupedActions, item) && !isManualEmailnotification(groupedActions)) {
                    togglePopup("confirm");
                } else {
                    handleEventCallback(callbackProperties);
                }
            });
        };

        const initEvents = (id = viewData.workflowphase_id) => {
            if (!id) {
                componentData.events = [];
                return;
            }
            if (id in workflowStore.workfloweventsbyphaseid) {
                componentData.events = workflowStore.workfloweventsbyphaseid[id];
                return;
            }
            // Set default data
            workflowStore.workfloweventsbyphaseid[id] = {};
            // Get our possible workflowevents and then match them to our values
            const tmpObject = {data: []};
            const filters = [
                [
                    "RelatedFilter",
                    {
                        "linked_ids": [id],
                        "match_all": false,
                        "module": "Workflowphase",
                        "module_link": "Workflowevent"
                    }
                ]
            ];
            // Now resolve our buttons
            getDataWithoutLimit(`${apiHostname}/${config.value.workflowevent.url}`, filters, tmpObject, "data").then(() => {
                const result = tmpObject.data.reduce((acc, obj) => {
                    acc[obj.id] = obj;
                    return acc;
                }, {});
                Object.assign(workflowStore.workfloweventsbyphaseid[id], result);
                componentData.events = result;
                Object.values(result).forEach(event => {
                    if (event.id in workflowStore.workfloweventactionbyeventid) return;
                    workflowStore.workfloweventactionbyeventid[event.id] = [];
                    getDataAsync(`${apiHostname}/${config.value.workflowevent.url}/${event.id}/links`).then(conditions => {
                        workflowStore.workfloweventactionbyeventid[event.id] = resolveData(conditions);
                    });
                });
            });
        };

        watch(
            () => usergroup_ids.value,
            (val) => {
                if (val === null) return;
                initEvents();
            },
            {
                immediate: true
            }
        );

        onMounted(() => {
            getItemsAndStore("workflowemailnotification");
            if (props.workflowevent_id) {
                getDataAsync(`${apiHostname}/${config.value.workflowevent.url}/${props.workflowevent_id}`).then(result => {
                    handleEvent(null, result.data);
                });
            }
        });
        onUnmounted(() => {
            document.body.classList.remove("VuePopupOpen");
        });

        // Appand from_email, from_name and from_reply
        const appendFromData = (formData, emailnotification) => {
            const id = formData.get("id");
            const emailnotification_data = emailnotification.data.find(a => `${a.id}` === id);
            formData.delete("id");
            ["from_email", "from_name", "from_reply"].forEach(key => {
                const value = emailnotification_data[`workflowemailnotification_${key}`];
                if (!value) return;
                formData.set(key, value);
            })
        };

        // Form handling
        const handleCustomComponentSubmitEvent = async (value) => {
            // Set field true if changes made, otherwise fallback to current state
            successCustomcomponentForm = true;
            setWorkflowpropertyfield(viewData.item.data, viewData.workflowcard_id, {
                id: popupData.customcomponent.data.property_id,
                type: "JsonField"
            }, value);
            // postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${form.action.id}/execute`, data);
            setTimeout(() => {
                togglePopup('customcomponent');
            }, 200);
        };
        const handleWorkflowformSubmitEvent = (e, form) => {
            handleWorkflowformSubmit(e.target, form);
            setTimeout(() => {
                togglePopup('workflowform');
            }, 200);
        };
        const handleWorkflowpropertySubmitEvent = (e) => {
            handleWorkflowpropertySubmit(e.target);
            setTimeout(() => {
                togglePopup('workflowproperty');
            }, 750);
        };
        // Our handling
        const handleWorkflowformSubmit = (form_element, form) => {
            // Our data
            const formData = new FormData(form_element);
            formData.set("workflowcard_id", viewData.workflowcard_id);

            // Date passed to store the correct fields
            formData.set("workflowcardproperty_id", form.action.workflowform_card_id);
            formData.set("workflowcardtype_id", form.action.workflowform_card_type);

            successWorkflowformForm = true;

            postDataAsync(`${apiHostname}/${config.value.workfloweventaction.url}/${form.action.id}/execute`, formData);
        };
        const handleWorkflowpropertySubmit = (form) => {
            // Our data
            const formData = new FormData(form);

            // @TODO add some kind of validation, so when invalid data is entered we don't trigger success
            successWorkflowpropertyForm = true;

            for (const [name, value] of formData.entries()) {
                // Resolve the correct property by name
                const property = popupData.workflowproperty.data.find(a => a.name === name);
                // Set field true if changes made, otherwise fallback to current state
                setWorkflowpropertyfield(viewData.item.data, viewData.workflowcard_id, {...property.field}, value);
            }
        };
        // Form handling
        const emailNotificationNextCallback = () => {
            // Handle both Success or Skip events.
            // If we cancel we won't run any of these
            for (const [key, callbackFunction] of Object.entries(eventActionsWatching)) {
                // Popup is closed and we have a callbackFunction
                callbackFunction && callbackFunction();
                eventActionsWatching[key] = null; // Reset
                successEmailnotificationForm = false;
            }
        }
        const handleEmailnotificationSkipEvent = (e) => {
            // When go Next without changes is clicked.
            // NOTE: This event shouldn't be used when Close is clicked.
            togglePopup('emailnotification');
            emailNotificationNextCallback();
        };
        const handleEmailnotificationSubmitEvent = (e) => {
            Array.from(document.querySelectorAll(".Emailnotification.Overview .cs_form")).forEach(form => {
                handleEmailnotificationSubmit(form);
            });
            setTimeout(() => {
                togglePopup('emailnotification');
                emailNotificationNextCallback();
            }, 200);
        };
        // Our handling
        const handleEmailnotificationSubmit = (form) => {
            // Our data
            const formData = new FormData(form);
            formData.set("card_id", viewData.workflowcard_id);
            [...new Set(Array.from(formData.keys()))].forEach(key => {
                if (!key.endsWith("[]")) return;
                if (key === 'email_cc[]' || key === 'email_bcc[]') {
                    const ids = [...formData.getAll(key)].filter(value => value !== "");
                    formData.set(key.replace("[]", ""), ids.join("; "));
                    formData.delete(key);
                    return;
                }
                // If the value was a array combine them and JSON stringify the array
                const ids = [...formData.getAll(key)];
                formData.set(key.replace("[]", ""), JSON.stringify(ids));
                formData.delete(key);
            });

            if (!popupData.emailnotification.allow_edit) {
                // UNSET CONTENT IN THAT CASE, MAKING SURE IT WON'T BE EDITTED
                formData.delete("content");
            }

            appendFromData(formData, popupData.emailnotification);

            // @TODO add some kind of validation, so when invalid data is entered we don't trigger success
            successEmailnotificationForm = true;

            postDataAsync(`${apiHostname}/${config.value.workflowemailnotificationcustom.url}`, formData)
                .then(result => {
                    const executeData = new FormData();
                    executeData.set("workflowcard_id", viewData.workflowcard_id);
                    postDataAsync(`${apiHostname}/${config.value.workflowemailnotificationcustom.url}/${result.data.id}/execute`, executeData);
                });

        };

        const setEmailnotificatioSelection = (item, id) => {
            item.selected = id;
        };
        const resolveEmailnotificationTemplates = (item) => {
            // If no id's passed at all, pass all templates
            if (!item.extra.workflowemailnotification.length) {
                return Object.values(workflowStore.workflowemailnotification.data);
            }
            // On only single selection, set active id
            if (item.extra.workflowemailnotification.length === 1) {
                setEmailnotificatioSelection(item, item.extra.workflowemailnotification[0].id);
            }
            return item.extra.workflowemailnotification;
        };
        const handleEmailnotificatioSelection = (e, item) => {
            const target = e.currentTarget || e.target;
            setEmailnotificatioSelection(item, target.value);
        };

        const resolveInputName = (item, name) => {
            return `workfloweventaction[${item.id}][${name}]`;
        };
        const resolveSendValue = (fullname, email) => {
            let val = fullname ? `<${fullname}> ` : ``;
            val += email;
            return val;
        };


        const formFields = (form) => {
            return computed(() => {
                const fields = [];
                form.extra.workflowformfields.forEach(data => {
                    const field = {
                        ...data.property,
                        extra: {
                            ...data
                        }
                    };
                    if (data?.field_type) {
                        field.type = data.field_type;
                    }
                    // Make sure company_property_name fields are hidden
                    if (field.name === env("company_property_name")) {
                        field.type = "HiddenField";
                    }
                    delete field.extra.property;
                    fields.push(field);
                });
                return fields;
            }).value;
        };
        const formValues = (form) => {
            return computed(() => {
                return workflowproperties(viewData.workflowcard_id);
            }).value;
        };

        const addCcEmailField = (index, value) => {
            // Check if the last input field is empty or if the blur event comes from the last field
            if (index === componentData.ccAddressFields.length - 1
                && componentData.ccAddressFields[index] === ''
                && /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
                && componentData.ccAddressFields.length < 3
            ) {
                // Add a new empty email field
                componentData.ccAddressFields.push('');
            }
        };
        const addBccEmailField = (index, value) => {
            // Check if the last input field is empty or if the blur event comes from the last field
            if (index === componentData.bccAddressFields.length - 1
                && componentData.bccAddressFields[index] === ''
                && /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
                && componentData.bccAddressFields.length < 3
            ) {
                // Add a new empty email field
                componentData.bccAddressFields.push('');
            }
        };

        const events = computed(() => {
            return Object.values(componentData.events).filter(item => {
                if (!item.extra.workflowusergroup.length) {
                    return true;
                } else {
                    const usergroupIds = item.extra.workflowusergroup.map(a => a.id);
                    return usergroupIds.some(id => usergroup_ids.value.includes(id));
                }
            });
        });


        const appendPortalValues = (fields, values) => {
            return computed(() => {

                const value_mapping = env("append_value_mapping") ?? [];

                Object.keys(value_mapping).forEach(name => {
                    // Check if the field name is defined within values
                    const targetValue = Object.values(values.value).find(a => a.field.name === name);
                    if (!targetValue) return;
                    // Since we have targetValue check if there is a field within fields with the value related
                    const targetField = fields.find(a => a.name === value_mapping[name]);
                    if (!targetField) return;
                    // Now set values based on targetField combined with targetValue
                    values.value[targetField.id] = Object.assign({}, targetValue);
                    values.value[targetField.id].field = Object.assign({}, targetField);
                    ["seq", "id"].forEach(key => {
                        if (!values.value?.[targetField.id]?.[key]) return;
                        delete values.value[targetField.id][key];
                    });
                });

                return values;
            }).value;
        };

        return {
            trans,
            attachments_json,
            componentData,
            handleEvent,

            // Popup related variables
            popupVisibility,
            popupData,
            togglePopup,
            handleConfirmClick,

            // Form actions
            handleCustomComponentSubmitEvent,

            handleWorkflowpropertySubmitEvent,
            handleWorkflowformSubmitEvent,

            handleEmailnotificationSubmitEvent,
            handleEmailnotificationSkipEvent,

            handleEmailnotificatioSelection,
            resolveEmailnotificationTemplates,

            resolveInputName,
            resolveSendValue,

            formFields,
            formValues,

            viewData,
            isCommunity,
            workflowproperties,

            validateVisibleForUsergroup,

            addCcEmailField,
            addBccEmailField,

            events,

            appendPortalValues
        };
    }
});
</script>

<style>
</style>